var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('h2',{staticClass:"brand-text text-primary ml-1"},[_c('vuexy-logo')],1)]),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"7"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Login V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"5"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold text-center  text-3xl mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" "+_vm._s(_vm.$t('message.login.header'))+" ! 👋 ")]),_c('b-card-text',{staticClass:"mb-2 text-center"},[_vm._v(" "+_vm._s(_vm.$t('message.login.subHeader'))+". "),_c('small',{staticClass:"text-danger text-center font-weight-bold",class:_vm.error_global ? 'block' : 'none'})]),_c('validation-observer',{ref:"loginValidation"},[_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"text-left"},[_c('label',{staticClass:"text-indigo font-weight-bold",attrs:{"for":"login-id"}},[_vm._v(_vm._s(_vm.$t('message.login.input.username'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('b-form-group',{attrs:{"label-for":"login-id"}},[_c('validation-provider',{attrs:{"name":"id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"login-id","state":errors.length > 0 ? false : null,"name":"login-ID","placeholder":"Qenium-ediqia"},on:{"input":_vm.validateID},model:{value:(_vm.userId),callback:function ($$v) {_vm.userId=$$v},expression:"userId"}}),_c('small',{staticClass:"text-danger",class:_vm.valideIdLen ? 'block' : 'none'},[_vm._v(" Veuillez entrer un username valide ")])]}}])}),(
                           _vm.inputError.path === 'code' ||
                              _vm.inputError.path === 'username'
                        )?_c('small',{staticClass:"text-danger",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.inputError.message)+" ")]):_vm._e()],1),_c('br'),_c('b-form-group',{attrs:{"label":"","label-for":"login-email"}},[_c('label',{attrs:{"for":""}},[_vm._v(" "+_vm._s(_vm.$t('message.login.input.email'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"login-email","state":errors.length > 0 ? false : null,"name":"login-email","placeholder":"john@exemple.com"},on:{"input":_vm.validateEmail},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger",class:_vm.valideEmail ? 'block' : 'none'},[_vm._v(" Veuillez entrer un email valide ")]),(_vm.inputError.path === 'email')?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.inputError.message)+" ")]):_vm._e()]}}])})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t('message.login.input.password'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-link',{attrs:{"to":{ name: 'reset-password' }}},[_c('small',[_vm._v(_vm._s(_vm.$t('message.login.input.forq'))+" ?")])])],1),_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":_vm.$t('message.login.input.phPassword')},on:{"input":_vm.validatePassword},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger",class:_vm.validePassword ? 'block' : 'none'},[_vm._v(" Le mot de passe est de 8 caractrères minimum ")]),(_vm.inputError.path === 'email')?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.inputError.message)+" ")]):_vm._e()]}}])})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"remember-me","name":"checkbox-1"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" "+_vm._s(_vm.$t('message.login.remenber'))+" ")])],1),(_vm.marche)?_c('b-button',{staticClass:"bg-indigo",attrs:{"type":"submit","block":"","disabled":""},on:{"click":_vm.signUp}},[_c('span',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status","aria-hidden":"true"}})]):_c('b-button',{staticClass:"bg-indigo",attrs:{"type":"submit","block":""},on:{"click":_vm.signUp}},[_vm._v(" "+_vm._s(_vm.$t('message.login.btn_login'))+" ")])],1)],1),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('message.login.is_login'))+" ")]),_c('b-link',{attrs:{"to":{ name: 'register' }}},[_c('span',[_vm._v("  "+_vm._s(_vm.$t('message.login.btn_create'))+" ")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }